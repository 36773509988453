<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <DspPartnerToolbar
          v-if="Object.keys(dsppartner).length !== 0"
          :dsppartner="dsppartner"
          closing
        />
        <hr>
      </div>
      <template
        v-if="logs.length !== 0"
      >
        <div
          v-for="(log, index) in logsFormat"
          :key="index"
          class="log-row mb-1"
          :class="{
            odd: index % 2 !== 0,
            even: index % 2 === 0
          }"
        >
          <p v-if="log.request.site !== undefined">
            <strong>{{ log.request.site.domain }}</strong>
          </p>
          <json-viewer
            :expand-depth="2"
            copyable
            boxed
            :value="log"
            @copied="copied()"
          />
          <hr>
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import DspPartnerToolbar from './DspPartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    DspPartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      breadcrumbItems: [],
      dsppartner: {},
      logs: [],
      errors: [],
      filterStatus: '',
      filterOptions: [],
    }
  },
  computed: {
    logsFormat() {
      return this.logs.map(l => {
        const obj = JSON.parse(l)
        const { response, request } = obj
        delete obj.request
        delete obj.response
        return { ...obj, response, request }
      })
    },
  },
  watch: {
    loading(newVal) {
      if (newVal === false) {
        setTimeout(() => {
          const copyButtons = document.querySelectorAll('.jv-tooltip .jv-button')
          if (copyButtons.length) {
            copyButtons.forEach(cb => {
              cb.innerHTML = this.$t('form.copy')
            })
          }
        }, 500)
      }
    },
  },
  async created() {
    this.getDspPartnerData()
    await this.fetchLogs()
  },
  methods: {
    async fetchLogs() {
      try {
        this.logs = await fetch(`https://dsp-eu.phoenix-widget.com/api/v1/requests?c=${this.$route.params.id}`, {
          method: 'get',
          headers: {
            Token: '8AD1dA9j3fpjD(2f1(@jfas2n91dKsd2dfbI',
          },
        })
          .then(response => response.json())
          .then(data => data)
      } catch (error) {
        this.errors.push(error)
      }
      this.loading = false
    },
    copied() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('form.copied'),
          icon: 'BellIcon',
        },
      })
    },
    async getDspPartnerData() {
      const responseData = await useJwt.getDsppartner(this.$route.params.id)
      this.dsppartner = responseData.data.dsppartner || {}
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        { text: this.$t('DSP partners'), to: '/dsppartners/' },
        { text: this.dsppartner.name, to: `/dsppartners/${this.$route.params.id}` },
        { text: this.$t('logs'), active: true },
      ]
    },
  },
}
</script>
